/*
 * @Author: fangjun
 * @Date: 2023-03-02 17:05:07
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2024-07-08 15:55:13
 * @FilePath: \src\store\konvaEditor\index.js
 */
import { useComposeStore } from './compose'
import { useContextMenuStore } from './contextmenu'
import { useCopyStore } from './copy'
import { useLayerStore } from './layer'
import { useSnapshotStore } from './snapshot'
import { useLockStore } from './lock'
import { useEditorStore } from './editor'
import { useStageStore } from './stage'
import { useToolStore } from './tool'
export function useKonvaAppStore() {
  return {
    compose: useComposeStore(),
    contextmenu: useContextMenuStore(),
    copy: useCopyStore(),
    layer: useLayerStore(),
    snapshot: useSnapshotStore(),
    lock: useLockStore(),
    editor: useEditorStore(),
    stage: useStageStore(),
    tool: useToolStore()
  }
}
/**
 * @description: 初始化Store State 切换地图需要
 * @return {*}
 */
export function initKonvaStoreState() {
  const konvaApp = useKonvaAppStore()
  Object.keys(konvaApp).forEach((key) => {
    konvaApp[key].initState && konvaApp[key].initState()
  })
}

//非清洁机器人

export const EVENT_SWITCH_MAP = {
  closeSonar: '超声',
  closeCamera: '相机',
  closeBumper: '触边',
  closeFall: '防跌落'
}
export const FEATURE_TYPE_ENUM = {
  AMR_POINT: 99, //机器人
  AVOID_POINT: -5, //避让点位
  AUXILIARY_POINT: -4, //辅助点位
  NORMAL_POINT: -3, //普通点位
  CHARGE_POINT: -2, //充电点位
  FORBID_LINE: -1, //禁行线
  FUNCTION_AREA: 0, //功能区
  FORBID_AREA: 1, //禁行区
  ENTRANCE_GUARD_AREA: 2, //门禁区
  CONTROL_AREA: 3, //控制区
  CLOUD_CONTROL_AREA: 4, //云控区
  ELEVATOR_AREA: 5, //电梯区
  CAR_BOX_AREA: 6, //轿厢区
  //清洁这里枚举是假的
  PLAN_AREA: 40,
  TYPHLOSUS_AREA: 41,
  SLOPE_AREA: 42,
  BUSINESS_AREA: 43
}

export const featureTypeOptions = [
  {
    label: '机器人',
    value: FEATURE_TYPE_ENUM.AMR_POINT,
    icon: 'edit-point',
    style: {
      color: '#333',
      stroke: '#409eff',
      fill: '#409eff'
    }
  },
  {
    label: '普通点位',
    value: FEATURE_TYPE_ENUM.NORMAL_POINT,
    icon: 'edit-point',
    style: {
      color: '#333',
      stroke: '#ff0000',
      fill: '#ff0000'
    }
  },
  {
    label: '充电点位',
    value: FEATURE_TYPE_ENUM.CHARGE_POINT,
    icon: 'edit-point',
    style: {
      color: '#333',
      stroke: '#66ff00',
      fill: '#66ff00'
    }
  },
  {
    label: '辅助点位',
    value: FEATURE_TYPE_ENUM.AUXILIARY_POINT,
    icon: 'edit-point',
    style: {
      color: '#333',
      stroke: '#33ffff',
      fill: '#33ffff'
    }
  },
  {
    label: '避让点位',
    value: FEATURE_TYPE_ENUM.AVOID_POINT,
    icon: 'edit-point',
    style: {
      color: '#333',
      stroke: '#ffae00',
      fill: '#ffae00'
    }
  },
  {
    label: '禁行线',
    value: FEATURE_TYPE_ENUM.FORBID_LINE,
    icon: 'forbid-line',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#000'
    }
  },
  {
    label: '普通功能区',
    value: FEATURE_TYPE_ENUM.FUNCTION_AREA,
    icon: 'function-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#005f73'
    }
  },
  {
    label: '禁行区',
    value: FEATURE_TYPE_ENUM.FORBID_AREA,
    icon: 'forbid-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#e63946'
    }
  },
  {
    label: '门禁区',
    value: FEATURE_TYPE_ENUM.ENTRANCE_GUARD_AREA,
    icon: 'entrance-guard-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#ff7f50'
    }
  },
  {
    label: '控制区',
    value: FEATURE_TYPE_ENUM.CONTROL_AREA,
    icon: 'control-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#a8dadc'
    }
  },
  {
    label: '云控区',
    value: FEATURE_TYPE_ENUM.CLOUD_CONTROL_AREA,
    icon: 'cloud-control-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#457b9d'
    }
  },
  {
    label: '电梯区',
    value: FEATURE_TYPE_ENUM.ELEVATOR_AREA,
    icon: 'elevator-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#e67e22'
    }
  },
  {
    label: '轿厢区',
    value: FEATURE_TYPE_ENUM.CAR_BOX_AREA,
    icon: 'car-box-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#f0e68c'
    }
  },
  {
    label: '规划区',
    value: FEATURE_TYPE_ENUM.PLAN_AREA,
    icon: 'plan-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#b19cd9'
    }
  },
  {
    label: '盲道区',
    value: FEATURE_TYPE_ENUM.TYPHLOSUS_AREA,
    icon: 'typhlosus-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#555'
    }
  },
  {
    label: '斜坡区',
    value: FEATURE_TYPE_ENUM.SLOPE_AREA,
    icon: 'slope-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#2a9d8f'
    }
  },
  {
    label: '业务区',
    value: FEATURE_TYPE_ENUM.BUSINESS_AREA,
    icon: 'business-area',
    style: {
      color: '#333',
      stroke: '#000',
      fill: '#006400'
    }
  }
]
export const featureTypeGroup = [
  {
    label: '机器人',
    value: 0,
    children: [FEATURE_TYPE_ENUM.AMR_POINT]
  },
  {
    label: '点位',
    value: 1,
    children: [
      FEATURE_TYPE_ENUM.NORMAL_POINT,
      FEATURE_TYPE_ENUM.CHARGE_POINT,
      FEATURE_TYPE_ENUM.AUXILIARY_POINT,
      FEATURE_TYPE_ENUM.AVOID_POINT
    ]
  },
  {
    label: '禁行线',
    value: 2,
    children: [FEATURE_TYPE_ENUM.FORBID_LINE]
  },
  {
    label: '功能区',
    value: 3,
    children: [
      FEATURE_TYPE_ENUM.FUNCTION_AREA,
      FEATURE_TYPE_ENUM.FORBID_AREA,
      FEATURE_TYPE_ENUM.ENTRANCE_GUARD_AREA,
      FEATURE_TYPE_ENUM.CONTROL_AREA,
      FEATURE_TYPE_ENUM.CLOUD_CONTROL_AREA,
      FEATURE_TYPE_ENUM.ELEVATOR_AREA,
      FEATURE_TYPE_ENUM.CAR_BOX_AREA,
      FEATURE_TYPE_ENUM.PLAN_AREA,
      FEATURE_TYPE_ENUM.TYPHLOSUS_AREA,
      FEATURE_TYPE_ENUM.SLOPE_AREA,
      FEATURE_TYPE_ENUM.BUSINESS_AREA
    ]
  }
]
/**
 * @description: Stage 操作枚举
 * @return {*}
 */
export const STAGE_OPERATION_TYPE_ENUM = {
  CLOSED: 0, //关闭
  DRAW_BROKEN_LINE: 2, //绘制折线
  DRAW_POLYGON: 3, //绘制多边形
  DRAW_OBSTACLE: 4, //绘制虚拟墙
  CLEAN_OBSTACLE: 5, //擦除噪点
  ERASER: 6, //清除
  ADD_POINT: 7 //点位
}

export const stageOperationTypeOptions = {
  [STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE]: {
    label: '绘制折线',
    icon: 'edit-broken-line',
    tips: '鼠标左键选取关键点(至少2个关键点)，鼠标右键完成'
  },
  [STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON]: {
    label: '绘制多边形',
    icon: 'edit-polygon',
    tips: '鼠标左键选取关键点(至少3个关键点)，鼠标右键完成'
  },
  [STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE]: {
    label: '绘制虚拟墙',
    icon: 'edit-draw-obstacle',
    tips: '鼠标左键选取关键点(至少2个关键点)，鼠标右键结束'
  },
  [STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE]: {
    label: '擦除噪点',
    icon: 'edit-clean-obstacle',
    tips: '按住鼠标左键进行拖动,松开结束'
  },
  [STAGE_OPERATION_TYPE_ENUM.ERASER]: {
    label: '清除边界',
    icon: 'edit-clear',
    tips: '按住鼠标左键进行拖动,松开结束'
  },
  [STAGE_OPERATION_TYPE_ENUM.ADD_POINT]: {
    label: '手动添加点位',
    icon: 'edit-marker',
    tips: '鼠标左键确认点位，鼠标右键退出'
  }
}

//清洁机器人
export const MATERIALTYPE_MAP = {
  0: '水泥',
  1: '瓷砖',
  2: '大理石',
  3: '地毯'
}
export const FUNCTION_SEERVICE_MAP = {
  0: '无',
  1: '清扫',
  2: '托管',
  3: '门禁',
  4: '云控',
  5: '避让',
  6: '不需要清扫区'
}
export const locationModeMap = {
  0: 'amcl定位',
  1: 'odom定位',
  2: '辅助定位',
  3: 'amcl+辅助定位',
  10: '其它'
}
export const EVENT_FUNCTION_SWITCH_MAP = {
  openSonarFunction: '超声',
  openCameraFunction: '相机',
  openBumperFunction: '触边',
  openLineLightFunction: '结构光',
  openLaserFunction: '雷达'
}
export const EVENT_HARDWARE_SWITCH_MAP = {
  openSonar: '硬件超声',
  openCamera: '相机',
  openBumper: '触边',
  openLineLight: '结构光',
  openLaser: '雷达'
}

export function getFeatureTypeEnumByMapType(mapType) {
  let result = [
    FEATURE_TYPE_ENUM.AMR_POINT,
    FEATURE_TYPE_ENUM.NORMAL_POINT,
    FEATURE_TYPE_ENUM.CHARGE_POINT,
    FEATURE_TYPE_ENUM.AUXILIARY_POINT,
    FEATURE_TYPE_ENUM.FORBID_LINE,
    FEATURE_TYPE_ENUM.FUNCTION_AREA,
    FEATURE_TYPE_ENUM.FORBID_AREA,
    FEATURE_TYPE_ENUM.ENTRANCE_GUARD_AREA,
    FEATURE_TYPE_ENUM.CONTROL_AREA,
    FEATURE_TYPE_ENUM.CLOUD_CONTROL_AREA,
    FEATURE_TYPE_ENUM.ELEVATOR_AREA,
    FEATURE_TYPE_ENUM.CAR_BOX_AREA
  ]
  switch (mapType) {
    case '4':
      result = [
        FEATURE_TYPE_ENUM.AMR_POINT,
        FEATURE_TYPE_ENUM.NORMAL_POINT,
        FEATURE_TYPE_ENUM.CHARGE_POINT,
        FEATURE_TYPE_ENUM.AUXILIARY_POINT,
        FEATURE_TYPE_ENUM.AVOID_POINT,
        FEATURE_TYPE_ENUM.FORBID_LINE,
        FEATURE_TYPE_ENUM.PLAN_AREA,
        FEATURE_TYPE_ENUM.FORBID_AREA,
        FEATURE_TYPE_ENUM.TYPHLOSUS_AREA,
        FEATURE_TYPE_ENUM.SLOPE_AREA,
        FEATURE_TYPE_ENUM.CAR_BOX_AREA,
        FEATURE_TYPE_ENUM.BUSINESS_AREA
      ]
      break

    default:
      break
  }
  return result
}

//映射真正的值
export function translateCleanFeatureType(value, isLocal = true) {
  let cftMap = {
    [FEATURE_TYPE_ENUM.PLAN_AREA]: 0,
    [FEATURE_TYPE_ENUM.FORBID_AREA]: 1,
    [FEATURE_TYPE_ENUM.TYPHLOSUS_AREA]: 2,
    [FEATURE_TYPE_ENUM.SLOPE_AREA]: 3,
    [FEATURE_TYPE_ENUM.CAR_BOX_AREA]: 6,
    [FEATURE_TYPE_ENUM.BUSINESS_AREA]: 7
  }
  if (isLocal) {
    return cftMap[value]
  } else {
    for (let key in cftMap) {
      if (cftMap[key] == value) {
        return Number(key)
      }
    }
  }
}
export function translatePointFeatureType(value, isLocal = true) {
  let pftMap = {
    [FEATURE_TYPE_ENUM.NORMAL_POINT]: 0,
    [FEATURE_TYPE_ENUM.CHARGE_POINT]: 1,
    [FEATURE_TYPE_ENUM.AUXILIARY_POINT]: 2,
    [FEATURE_TYPE_ENUM.AVOID_POINT]: 3
  }
  if (isLocal) {
    return pftMap[value]
  } else {
    for (let key in pftMap) {
      if (pftMap[key] == value) {
        return Number(key)
      }
    }
  }
}
