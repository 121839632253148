/*
 * @Author: fangjun
 * @Date: 2023-03-16 17:06:43
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-04-28 15:43:15
 * @FilePath: \src\utils\konvaEditor\utils.js
 */
import { cloneDeep } from 'lodash'

export function deepCopy(target) {
  return cloneDeep(target)
}

export function swap(arr, i, j) {
  const temp = arr[i]
  arr[i] = arr[j]
  arr[j] = temp
}

export function $(selector) {
  return document.querySelector(selector)
}

export function base64ToImg(base64) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = base64
    img.onload = function () {
      resolve(img)
    }
    img.onerror = function (e) {
      reject(e)
    }
  })
}
export function downloadURI(uri, name) {
  var link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
// 判断obj是否为对象
export function isObj(obj) {
  return typeof obj === 'object' && obj !== null
}

export function deepCompare(obj1, obj2, ignoreKey = []) {
  // 1.判断一个或者两个都不是对象
  if (!isObj(obj1) || !isObj(obj2)) {
    return obj1 === obj2
  }

  // 2.同一个对象
  if (obj1 === obj2) {
    return true
  }
  // 3.不是同一个对象
  // Object.keys(obj) 一个表示给定对象的所有可枚举属性的字符串数组
  // 先判断键的数量
  // 3.1不一样
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
  }
  // 3.2一样
  for (let key in obj1) {
    if (ignoreKey.includes(key)) continue
    const res = deepCompare(obj1[key], obj2[key], ignoreKey)
    if (!res) {
      return false
    }
  }

  return true
}
