import { MIN_RECT } from '@/utils/konvaEditor/style'
// 公共样式
export const commonStyle = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  rotate: 0,
  opacity: 1,
  remark: ''
}

export const commonAttr = {
  type: 'normal',
  groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
  isLock: false, // 是否锁定组件,
  canResize: false,
  canRotate: false,
  canRePosition: true,
  visible: true
}
export const stageComponentData = {
  ...commonAttr,
  component: 'konva-stage',
  label: 'KonvaStage',
  type: 'stage',
  propValue: '',
  icon: 'stage',
  visible: true,
  mapData: null,
  canRePosition: false,
  attrs: {
    curPos: null,
    isPaint: false,
    eraserLineIdList: [],
    obstaclePoints: [],
    stroke: '#000',
    strokeWidth: 1,
    fill: '#2899a1',
    eraserStroke: '#000',
    eraserStrokeWidth: 5,
    eraserGlobalCompositeOperation: 'source-over',
    imageData: '',
    data: []
  },
  style: {
    ...commonStyle,
    width: 200,
    height: 200
  }
}
export const customComponentData = {
  ...commonAttr,
  component: 'konva-line',
  label: '自定义',
  propValue: '',
  icon: 'line',
  type: 'customLine',
  canResize: true,
  canRotate: true,
  attrs: {
    name: '自定义',
    color: '#005DBE',
    fontSize: 12,
    featureType: '',
    tension: 0, //0直线 1曲线
    stroke: '#fff',
    strokeWidth: 1,
    fill: '#aaa',
    closed: false,
    maxSpeed: 0,
    eventData: {
      openBumper: true,
      openBumperFunction: true,
      openCamera: true,
      openCameraFunction: true,
      openLaser: true,
      openLaserFunction: true,
      openLineLight: true,
      openLineLightFunction: true,
      openSonar: true,
      openSonarFunction: true,
      closeBumper: false,
      closeCamera: false,
      closeFall: false,
      closeSonar: false
    },
    transactionType: 0,
    locateMode: 0,
    material: 0,
    data: [],
    originStyle: null
  },
  style: {
    ...commonStyle,
    width: 50,
    height: 50
  }
}
export const pointComponentData = {
  ...commonAttr,
  component: 'konva-point',
  label: '自定义',
  propValue: '',
  icon: 'point',
  type: 'point',
  canResize: false,
  canRotate: false,
  attrs: {
    name: '自定义',
    color: '#005DBE',
    fontSize: 12,
    featureType: '',
    stroke: '#fff',
    strokeWidth: 1,
    fill: '#aaa',
    closed: false,
    yaw: 0,
    data: []
  },
  style: {
    ...commonStyle,
    width: 10,
    height: 10
  }
}
// 编辑器左侧组件列表
const list = [
  // {
  //   component: 'konva-line',
  //   label: '直线',
  //   propValue: '',
  //   icon: 'line',
  //   type: 'line',
  //   canResize: true,
  //   canRotate: true,
  //   attrs: {
  //     name: '直线',
  //     color: '#005DBE',
  //     fontSize: 12,
  //     featureType: '',
  //     tension: 0, //0直线 1曲线
  //     stroke: '#000',
  //     strokeWidth: 1,
  //     data: [0, 0, 50, 50]
  //   },
  //   style: {
  //     width: 50,
  //     height: 50
  //   }
  // },

  {
    component: 'konva-line',
    label: '矩形',
    propValue: '',
    icon: 'rect',
    type: 'rect',
    canResize: true,
    canRotate: true,
    attrs: {
      name: '矩形',
      color: '#005DBE',
      fontSize: 12,
      fill: '#aaa',
      stroke: '#000',
      strokeWidth: 1,
      featureType: '',
      closed: true,
      maxSpeed: 0,
      eventData: {
        openBumper: true,
        openBumperFunction: true,
        openCamera: true,
        openCameraFunction: true,
        openLaser: true,
        openLaserFunction: true,
        openLineLight: true,
        openLineLightFunction: true,
        openSonar: true,
        openSonarFunction: true,
        closeBumper: false,
        closeCamera: false,
        closeFall: false,
        closeSonar: false
      },
      transactionType: 0,
      locateMode: 0,
      material: 0,
      data: [0, 0, 0, 50, 50, 50, 50, 0, 0, 0]
    },
    style: {
      width: 50,
      height: 50,
      opacity: 0.4
    }
  },
  {
    component: 'konva-line',
    label: '三角形',
    propValue: '',
    icon: 'triangle',
    type: 'triangle',
    canResize: true,
    canRotate: true,
    attrs: {
      name: '三角形',
      color: '#005DBE',
      fontSize: 12,
      fill: '#aaa',
      stroke: '#000',
      strokeWidth: 1,
      featureType: '',
      closed: true,
      maxSpeed: 0,
      eventData: {
        openBumper: true,
        openBumperFunction: true,
        openCamera: true,
        openCameraFunction: true,
        openLaser: true,
        openLaserFunction: true,
        openLineLight: true,
        openLineLightFunction: true,
        openSonar: true,
        openSonarFunction: true,
        closeBumper: false,
        closeCamera: false,
        closeFall: false,
        closeSonar: false
      },
      transactionType: 0,
      locateMode: 0,
      material: 0,
      data: [25, 0, 50, 50, 0, 50, 25, 0]
    },
    style: {
      width: 50,
      height: 50,
      opacity: 0.4
    }
  },
  {
    component: 'konva-line',
    label: '圆形',
    propValue: '',
    icon: 'polygon',
    type: 'polygon',
    canResize: true,
    canRotate: true,
    attrs: {
      name: '圆形',
      color: '#005DBE',
      fontSize: 12,
      fill: '#aaa',
      stroke: '#000',
      strokeWidth: 1,
      featureType: '',
      pointNumber: 12,
      closed: true,
      maxSpeed: 0,
      eventData: {
        openBumper: true,
        openBumperFunction: true,
        openCamera: true,
        openCameraFunction: true,
        openLaser: true,
        openLaserFunction: true,
        openLineLight: true,
        openLineLightFunction: true,
        openSonar: true,
        openSonarFunction: true,
        closeBumper: false,
        closeCamera: false,
        closeFall: false,
        closeSonar: false
      },
      transactionType: 0,
      locateMode: 0,
      material: 0,
      data: []
    },
    style: {
      width: 50,
      height: 50,
      opacity: 0.4
    }
  }
]

for (let i = 0, len = list.length; i < len; i++) {
  const item = list[i]
  item.style = { ...commonStyle, ...item.style }
  list[i] = { ...commonAttr, ...item }

  item.style.width += MIN_RECT
  item.style.height += MIN_RECT
  item.attrs.data = item.attrs.data.map((point) => (point += MIN_RECT / 2))
}

export function getTypeConfigData(type) {
  let result = list.find((data) => data.type === type)
  return result ?? null
}
export default list
