/*
 * @Author: fangjun
 * @Date: 2023-07-11 08:58:28
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-07-18 15:06:00
 * @FilePath: \src\store\konvaEditor\tool.js
 */
import { defineStore } from 'pinia'

// 工具类枚举
export const STAGE_TOOL_TYPE_ENUM = {
  CLOSED: 0,
  RANGE: 1 //测距
}
//工具类配置
export const STAGE_TOOL_TYPE_CONFIG = {
  [STAGE_TOOL_TYPE_ENUM.RANGE]: {
    showTips: true,
    option: {
      label: '测距',
      icon: 'edit-range',
      tips: '鼠标左键选取关键点(至少2个关键点)，鼠标右键完成单次测量。点击测距按钮关闭测距功能。'
    }
  }
}

export const useToolStore = defineStore({
  id: 'tool',
  state: () => {
    return {
      tool: STAGE_TOOL_TYPE_ENUM.CLOSED
    }
  },
  getters: {
    toolOpened: (state) => {
      return state.tool !== STAGE_TOOL_TYPE_ENUM.CLOSED
    },
    isRanging: (state) => {
      return state.tool === STAGE_TOOL_TYPE_ENUM.RANGE
    },
    curConfig: (state) => {
      return STAGE_TOOL_TYPE_CONFIG[state.tool] ?? null
    }
  },
  actions: {
    initState() {
      this.tool = STAGE_TOOL_TYPE_ENUM.CLOSED
    },
    openTool(toolType) {
      this.tool = toolType
    },
    closeTool() {
      this.tool = STAGE_TOOL_TYPE_ENUM.CLOSED
    },
    triggerTool(toolType) {
      if (this.tool !== STAGE_TOOL_TYPE_ENUM.CLOSED) {
        this.closeTool()
      } else {
        this.openTool(toolType)
      }
    }
  }
})

export default useToolStore
