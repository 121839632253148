/*
 * @Author: fangjun
 * @Date: 2023-02-16 18:02:41
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-03-15 11:58:25
 * @FilePath: \src\store\konvaEditor\contextmenu.js
 */
import { defineStore } from 'pinia'
export const useContextMenuStore = defineStore({
  id: 'contextMenu ',
  state: () => {
    return {
      menuTop: 0, // 右击菜单数据
      menuLeft: 0,
      menuShow: false
    }
  },
  actions: {
    initState() {
      this.menuTop = 0 // 右击菜单数据
      this.menuLeft = 0
      this.menuShow = false
    },
    showContextMenu({ top, left }) {
      this.menuShow = true
      this.menuTop = top
      this.menuLeft = left
    },

    hideContextMenu() {
      this.menuShow = false
    }
  }
})

export default useContextMenuStore
