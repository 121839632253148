/*
 * @Author: fangjun
 * @Date: 2023-03-06 15:54:13
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-04-28 15:52:02
 * @FilePath: \src\store\konvaEditor\layer.js
 */
import { swap, $ } from '@/utils/konvaEditor/utils'
import { defineStore } from 'pinia'
import { useEditorStore } from './editor'
import { ElMessage } from 'element-plus'
import { useSnapshotStore } from './snapshot'

export const useLayerStore = defineStore({
  id: 'layer',
  state: () => {
    return {
      show: false
    }
  },
  actions: {
    initState() {
      this.show = false
    },
    showLayer() {
      this.show = true
    },
    hiddenLayer() {
      this.show = false
    },
    toggleShowLayer() {
      this.show = !this.show
    },
    upComponent(component = null) {
      const editorApp = useEditorStore()
      const snapshotApp = useSnapshotStore()
      let index = -1
      if (component) {
        index = editorApp.getComponentIndex(component)
      } else {
        index = editorApp.curComponentIndex
      }
      // 上移图层 index，表示元素在数组中越往后
      if (index < editorApp.componentData.length - 1) {
        swap(editorApp.componentData, index, index + 1)
        snapshotApp.recordSnapshot()
      } else {
        ElMessage({ message: '已经到顶了', appendTo: editorApp.isFullscreen ? $('.konva-editor-container') : '' })
      }
    },

    downComponent(component = null) {
      const editorApp = useEditorStore()
      const snapshotApp = useSnapshotStore()
      let index = -1
      if (component) {
        index = editorApp.getComponentIndex(component)
      } else {
        index = editorApp.curComponentIndex
      }
      // 下移图层 index，表示元素在数组中越往前
      if (index > 1) {
        swap(editorApp.componentData, index, index - 1)
        snapshotApp.recordSnapshot()
      } else {
        ElMessage({
          message: '已经到底了',
          appendTo: editorApp.isFullscreen ? $('.konva-editor-container') : ''
        })
      }
    },

    topComponent(component = null) {
      const editorApp = useEditorStore()
      const snapshotApp = useSnapshotStore()
      let index = -1
      if (component) {
        index = editorApp.getComponentIndex(component)
      } else {
        index = editorApp.curComponentIndex
        component = editorApp.curComponent
      }
      // 置顶
      if (index < editorApp.componentData.length - 1) {
        editorApp.componentData.splice(index, 1)
        editorApp.componentData.push(component)
        snapshotApp.recordSnapshot()
      } else {
        ElMessage({ message: '已经到顶了', appendTo: editorApp.isFullscreen ? $('.konva-editor-container') : '' })
      }
    },

    bottomComponent(component = null) {
      const editorApp = useEditorStore()
      const snapshotApp = useSnapshotStore()
      let index = -1
      if (component) {
        index = editorApp.getComponentIndex(component)
      } else {
        index = editorApp.curComponentIndex
        component = editorApp.curComponent
      }
      // 置底 0是 stage不参与
      if (index > 1) {
        editorApp.componentData.splice(index, 1)
        editorApp.componentData.splice(1, 0, component)
        snapshotApp.recordSnapshot()
      } else {
        ElMessage({ message: '已经到底了', appendTo: editorApp.isFullscreen ? $('.konva-editor-container') : '' })
      }
    }
  }
})

export default useLayerStore
