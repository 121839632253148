import { defineStore } from 'pinia'
import { useEditorStore } from './editor'
export const useLockStore = defineStore({
  id: 'lock',
  actions: {
    lock() {
      const editorApp = useEditorStore()
      editorApp.curComponent.isLock = true
    },

    unlock() {
      const editorApp = useEditorStore()
      editorApp.curComponent.isLock = false
    }
  }
})

export default useLockStore
