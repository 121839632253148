/*
 * @Author: fangjun
 * @Date: 2023-03-07 11:22:30
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2024-07-10 09:10:07
 * @FilePath: \src\store\konvaEditor\editor.js
 */
import { defineStore } from 'pinia'
import { STAGE_OPERATION_TYPE_ENUM } from './index'
import { useSnapshotStore } from './snapshot'

export const useEditorStore = defineStore({
  id: 'editor',
  state: () => {
    return {
      loading: {
        data: false,
        save: false
      },
      editMode: false,
      drawMode: STAGE_OPERATION_TYPE_ENUM.CLOSED, // 绘制模式
      canvasStyleData: {
        // 页面全局数据
        width: 2000,
        height: 2000,
        scale: 0
      },
      limitScale: { max: 200, min: 50 },
      editorBoxStyle: {
        width: null,
        height: null
      },
      componentData: [], // 画布组件数据
      curComponent: null,
      curComponentIndex: null,
      // 点击画布时是否点中组件，主要用于取消选中组件用。
      // 如果没点中组件，并且在画布空白处弹起鼠标，则取消当前组件的选中状态
      isClickComponent: false,
      isCtrlDown: false,
      showAttrListPanel: false,
      showComponentListPanel: true,
      isFullscreen: false
    }
  },
  getters: {
    isDrawing: (state) => {
      return state.drawMode !== STAGE_OPERATION_TYPE_ENUM.CLOSED
    },
    isCleaning: (state) => {
      return state.drawMode === STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE
    },
    isErasing: (state) => {
      return state.drawMode === STAGE_OPERATION_TYPE_ENUM.ERASER
    },
    isAddPoint: (state) => {
      return state.drawMode === STAGE_OPERATION_TYPE_ENUM.ADD_POINT
    },
    closed: (state) => {
      return state.drawMode === STAGE_OPERATION_TYPE_ENUM.CLOSED
    },
    stageComponentData: (state) => {
      return state.componentData.find((component) => component.type === 'stage')
    },
    amrComponentData: (state) => {
      return state.componentData.filter((component) => component.type === 'amr')
    },
    normalComponentData: (state) => {
      return state.componentData.filter((component) => !['stage', 'amr'].includes(component.type))
    }
  },
  actions: {
    initState() {
      this.loading = {
        data: false,
        save: false
      }
      this.editMode = false
      this.drawMode = STAGE_OPERATION_TYPE_ENUM.CLOSED
      this.canvasStyleData = {
        // 页面全局数据
        width: 2000,
        height: 2000,
        scale: 0
      }
      this.limitScale = { max: 200, min: 50 }
      this.editorBoxStyle = {
        width: null,
        height: null
      }
      this.componentData = []
      this.curComponent = null
      this.curComponentIndex = null
      this.isClickComponent = false
      this.isCtrlDown = false
      this.showAttrListPanel = false
      this.showComponentListPanel = true
      this.isFullscreen = false
    },

    setEditMode(value) {
      if (typeof value !== 'boolean') {
        return
      }
      this.editMode = value
    },
    setLoading(loading) {
      this.loading = { ...this.loading, ...loading }
    },
    setClickComponentStatus(status) {
      this.isClickComponent = status
    },

    setDrawMode(mode) {
      this.drawMode = mode
    },
    /**
     * @description:
     * @param {*} width image.width
     * @param {*} height image.height
     * @return {*}
     */
    calcLimitScale({ width, height }) {
      let maxPx = 3000, //画布建议最大尺寸 大了卡顿
        minPx = 500,
        maxSide = Math.max(width, height)
      this.limitScale.max = (maxPx / maxSide) * 100
      this.limitScale.min = (minPx / maxSide) * 100
    },
    closeDrawMode() {
      this.drawMode = STAGE_OPERATION_TYPE_ENUM.CLOSED
    },
    setCtrlStatus(value) {
      this.isCtrlDown = value
    },
    setCanvasStyle(style) {
      this.canvasStyleData = style
    },
    setEditorBoxStyle(style) {
      if (!style) return
      let { height, width } = style
      if (height) {
        this.editorBoxStyle.height = height
      }
      if (width) {
        this.editorBoxStyle.width = width
      }
    },
    setCurComponent({ component, index }) {
      if (this.isDrawing) return
      this.curComponent = component
      this.curComponentIndex = index
    },
    getComponentIndex(item) {
      return this.componentData.findIndex((component) => component.id === item.id)
    },
    setStageToCurComponent() {
      // 0 是stage
      if (this.curComponentIndex === 0) return
      this.setCurComponent({
        component: this.componentData[0],
        index: 0
      })
    },
    setShapeStyle(style) {
      this.curComponent.style = { ...this.curComponent.style, ...style }
    },
    setStageShapeStyle(style) {
      this.stageComponentData.style = { ...this.stageComponentData.style, ...style }
    },

    setShapeSingleStyle({ key, value }) {
      this.curComponent.style[key] = value
    },

    setComponentData(componentData = []) {
      this.componentData = componentData
    },

    addComponent({ component, index }) {
      if (index !== undefined) {
        this.componentData.splice(index, 0, component)
      } else {
        this.componentData.push(component)
      }
    },

    deleteComponent(index) {
      if (index === undefined) {
        index = this.curComponentIndex
      }

      if (index == this.curComponentIndex) {
        this.curComponentIndex = null
        this.curComponent = null
      }
      this.componentData.splice(index, 1)
      const snapshotApp = useSnapshotStore()
      snapshotApp.recordSnapshot()
    },
    toggleShowAttrListPanel(value = undefined) {
      this.showAttrListPanel = value == undefined ? !this.showAttrListPanel : value
    },
    toggleShowComponentListPanel(value = undefined) {
      this.showComponentListPanel = value == undefined ? !this.showComponentListPanel : value
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen
    }
  }
})
