/*
 * @Author: fangjun
 * @Date: 2023-03-07 11:22:30
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-03-15 15:26:32
 * @FilePath: \src\store\konvaEditor\compose.js
 */
import generateID from '@/utils/konvaEditor/generateID'
import { emitter } from '@/utils/mitt'
import decomposeComponent from '@/utils/konvaEditor/decomposeComponent'
import { $ } from '@/utils/konvaEditor/utils'
import { commonAttr, commonStyle } from '@/konva/components/component-list'
import createGroupStyle from '@/utils/konvaEditor/createGroupStyle'
import { defineStore } from 'pinia'
import { useEditorStore } from './editor'
export const useComposeStore = defineStore({
  id: 'compose',
  state: () => {
    return {
      areaData: {
        // 选中区域包含的组件以及区域位移信息
        style: {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        },
        components: []
      },
      editor: null
    }
  },
  actions: {
    initState() {
      this.areaData = {
        // 选中区域包含的组件以及区域位移信息
        style: {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        },
        components: []
      }
      this.editor = null
    },
    getEditor() {
      this.editor = $('#editor')
    },

    setAreaData(data) {
      this.areaData = data
    },

    compose() {
      const editorApp = useEditorStore()
      const components = []
      this.areaData.components.forEach((component) => {
        if (component.component != 'group') {
          components.push(component)
        } else {
          // 如果要组合的组件中，已经存在组合数据，则需要提前拆分
          const parentStyle = { ...component.style }
          const subComponents = component.propValue
          const editorRect = this.editor.getBoundingClientRect()

          editorApp.deleteComponent()

          subComponents.forEach((component) => {
            decomposeComponent(component, editorRect, parentStyle)
            editorApp.addComponent({ component })
          })

          components.push(...component.propValue)
          this.batchDeleteComponent(component.propValue)
        }
      })

      const groupComponent = {
        id: generateID(),
        component: 'group',
        ...commonAttr,
        type: 'group',
        attrs: {
          name: 'group'
        },
        style: {
          ...commonStyle,
          ...this.areaData.style
        },
        propValue: components
      }
      createGroupStyle(groupComponent)

      editorApp.addComponent({ component: groupComponent })
      this.batchDeleteComponent(this.areaData.components)
      editorApp.setCurComponent({
        component: editorApp.componentData[editorApp.componentData.length - 1],
        index: editorApp.componentData.length - 1
      })
      // this.areaData.components = []
      emitter.emit('hideArea')
    },

    // 将已经放到 Group 组件数据删除，也就是在 componentData 中删除，因为它们已经放到 Group 组件中了
    batchDeleteComponent(deleteData) {
      const editorApp = useEditorStore()
      deleteData.forEach((component) => {
        for (let i = 0, len = editorApp.componentData.length; i < len; i++) {
          if (component.id == editorApp.componentData[i].id) {
            editorApp.componentData.splice(i, 1)
            break
          }
        }
      })
    },

    decompose() {
      const editorApp = useEditorStore()
      const parentStyle = { ...editorApp.curComponent.style }
      const components = editorApp.curComponent.propValue
      const editorRect = this.editor.getBoundingClientRect()

      editorApp.deleteComponent()
      components.forEach((component) => {
        decomposeComponent(component, editorRect, parentStyle)
        editorApp.addComponent({ component })
      })
    }
  }
})

export default useComposeStore
