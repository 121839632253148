/*
 * @Author: fangjun
 * @Date: 2023-02-16 18:02:42
 * @LastEditors: fangjun
 * @Description:.
 * @LastEditTime: 2023-04-18 10:40:41
 * @FilePath: \src\store\konvaEditor\snapshot.js
 */
import { deepCopy } from '@/utils/konvaEditor/utils'
import { defineStore } from 'pinia'
import { useEditorStore } from './editor'
import { emitter } from '@/utils/mitt'
import { nextTick } from 'vue'

export const useSnapshotStore = defineStore({
  id: 'snapshot',
  state: () => {
    return {
      snapshotData: [], // 编辑器快照数据
      snapshotIndex: -1 // 快照索引
    }
  },
  getters: {
    canUndo: (state) => {
      return state.snapshotIndex > 0
    },
    canRedo: (state) => {
      return state.snapshotIndex < state.snapshotData.length - 1
    }
  },
  actions: {
    initState() {
      this.snapshotData = []
      this.snapshotIndex = -1
    },
    undo() {
      if (this.snapshotIndex > 0) {
        this.snapshotIndex--
        this.restoreData(this.snapshotData[this.snapshotIndex])
      }
    },

    redo() {
      if (this.snapshotIndex < this.snapshotData.length - 1) {
        this.snapshotIndex++
        this.restoreData(this.snapshotData[this.snapshotIndex], false)
      }
    },
    restoreData(snapshotData, type = 'undo') {
      console.log('restoreData')
      const editorApp = useEditorStore()
      let copySnapshotData = deepCopy(snapshotData)
      if (type == 'undo') {
        if (editorApp.curComponent) {
          // 如果当前组件不在 componentData 中，则置空
          let needClean = !copySnapshotData.componentData.find(
            (component) => editorApp.curComponent.id === component.id
          )

          if (needClean) {
            editorApp.setCurComponent({
              component: null,
              index: null
            })
          }
        }
      }
      // let amrComponentData = editorApp.componentData.filter((component) => component.type === 'amr')
      // editorApp.setComponentData(copySnapshotData.componentData.concat(amrComponentData))
      let curScale = editorApp.canvasStyleData.scale

      this.updateComponentDataBySnapshot(copySnapshotData)
      editorApp.setCanvasStyle(copySnapshotData.canvasStyle)
      editorApp.setDrawMode(copySnapshotData.drawMode)

      if (curScale !== copySnapshotData.canvasStyle.scale) {
        emitter.emit('setScale', curScale)
      } else {
        emitter.emit('setScaleValue', copySnapshotData.canvasStyle.scale)
      }
    },
    recordSnapshot() {
      console.log('recordSnapshot')
      // return
      const editorApp = useEditorStore()
      // 添加新的快照
      let otherComponentData = editorApp.componentData.filter((component) => component.type !== 'amr')
      this.snapshotData[++this.snapshotIndex] = {
        componentData: deepCopy(otherComponentData),
        canvasStyle: deepCopy(editorApp.canvasStyleData),
        drawMode: deepCopy(editorApp.drawMode)
      }
      // 在 undo 过程中，添加新的快照时，要将它后面的快照清理掉
      if (this.snapshotIndex < this.snapshotData.length - 1) {
        this.snapshotData = this.snapshotData.slice(0, this.snapshotIndex + 1)
      }
    },
    updateComponentDataBySnapshot(snapshotData) {
      const editorApp = useEditorStore()
      let amrComponentData = editorApp.componentData.filter((component) => component.type === 'amr')
      let curStageData = editorApp.stageComponentData
      let snapshotStageData = snapshotData.componentData.find((component) => component.type === 'stage')
      let offsetX = snapshotStageData.style.left - curStageData.style.left
      let offsetY = snapshotStageData.style.top - curStageData.style.top

      let newComponentData = snapshotData.componentData.map((component) => {
        if (component.type !== 'stage') {
          component.style.left -= offsetX
          component.style.top -= offsetY
        } else {
          component.style.left = curStageData.style.left
          component.style.top = curStageData.style.top
        }
        let backupComponentData = editorApp.componentData.find((backData) => backData.id === component.id)
        if (!backupComponentData) return component
        let { attrs, style } = component
        return { ...backupComponentData, attrs, style }
      })

      editorApp.setComponentData(newComponentData.concat(amrComponentData))
    }
  }
})
export default useSnapshotStore
