/*
 * @Author: fangjun
 * @Date: 2023-03-06 15:54:13
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-04-26 15:48:25
 * @FilePath: \src\store\konvaEditor\copy.js
 */
// import store from './index';
import generateID from '@/utils/konvaEditor/generateID'
import { deepCopy, $ } from '@/utils/konvaEditor/utils'
import { defineStore } from 'pinia'
import { useEditorStore } from './editor'
import { useContextMenuStore } from './contextmenu'
import { useSnapshotStore } from './snapshot'
import { ElMessage } from 'element-plus'
import { nextTick } from 'vue'

export const useCopyStore = defineStore({
  id: 'copy',
  state: () => {
    return {
      copyData: null, // 复制粘贴剪切
      isCut: false
    }
  },
  getters: {
    hasCopyData: (state) => {
      return state.copyData !== null
    }
  },
  actions: {
    initState() {
      this.copyData = null
      this.isCut = false
    },
    copy() {
      const editorApp = useEditorStore()
      if (!editorApp.curComponent) {
        return
      }
      this.copyData = {
        data: deepCopy(editorApp.curComponent),
        index: editorApp.curComponentIndex
      }

      this.isCut = false
    },

    paste(isMouse) {
      const editorApp = useEditorStore()
      const snapshotApp = useSnapshotStore()
      const contextmenuApp = useContextMenuStore()
      if (!this.copyData) {
        ElMessage({ message: '请选择标注', appendTo: editorApp.isFullscreen ? $('.konva-editor-container') : '' })
        return
      }
      const data = this.copyData.data

      if (isMouse) {
        data.style.top = contextmenuApp.menuTop
        data.style.left = contextmenuApp.menuLeft
      } else {
        data.style.top += 10
        data.style.left += 10
      }

      data.id = generateID()
      // Group 的子组件根节点的 id 是通过组件的 id 生成的，必须重新生成 id，否则拆分 Group 的时候获取根节点不正确
      if (data.component === 'group') {
        data.propValue.forEach((component) => {
          component.id = generateID()
        })
      }
      if (this.isCut) {
        this.copyData = null
      } else {
        data.attrs.name = '复制-' + data.id.substring(0, 6)
      }
      let copyComponent = deepCopy(data)
      editorApp.addComponent({ component: copyComponent })
      snapshotApp.recordSnapshot()
      nextTick(() => {
        let index = editorApp.getComponentIndex(copyComponent)
        editorApp.setClickComponentStatus(true)
        editorApp.setCurComponent({
          component: editorApp.componentData[index],
          index
        })
      })
    },

    cut() {
      const editorApp = useEditorStore()

      if (!editorApp.curComponent) {
        ElMessage({ message: '请选择标注', appendTo: editorApp.isFullscreen ? $('.konva-editor-container') : '' })
        return
      }

      // if (this.copyData) {
      //   const data = deepCopy(this.copyData.data)
      //   const index = this.copyData.index
      //   data.id = generateID()
      //   editorApp.addComponent({ component: data, index })
      //   if (editorApp.curComponentIndex >= index) {
      //     // 如果当前组件索引大于等于插入索引，需要加一，因为当前组件往后移了一位
      //     editorApp.curComponentIndex++
      //   }
      // }

      this.copy()
      editorApp.deleteComponent()
      this.isCut = true
    }
  }
})
export default useCopyStore
