/*
 * @Author: fangjun
 * @Date: 2023-03-08 15:44:00
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-03-15 11:42:41
 * @FilePath: \src\store\konvaEditor\stage.js
 */
import { defineStore } from 'pinia'
import Konva from 'konva'

export const useStageStore = defineStore({
  id: 'stage',
  state: () => {
    return {
      stage: null,
      layers: {},
      groups: [],
      shapes: [],
      mapData: null
    }
  },
  actions: {
    initState() {
      this.stage = null
      this.layers = {}
      this.groups = []
      this.shapes = []
      this.mapData = null
    },
    setStage(stage) {
      this.stage = stage
    },
    setMapData(data) {
      this.mapData = data
    }
  }
})
export default useStageStore
